import React from "react";
import Slider from '../SliderAmenidades/Slider'
import { SwiperSlide } from "swiper/react";
import './Amenidades.scss'
const Amenidades = (props) => {
return(
<>
<div className="contenedor-amenidades" id="amenidades">
    <div className="contenedor-i-amenidades">
        <div className="contenedor-i-principal-amenidades">
      <Slider nav={true} pag={false}>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/Assets/Imagenes/img-amenidad-alberca-artelia-residencial.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/Assets/Imagenes/img-amenidad-area-infantil-artelia-residencial.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/Assets/Imagenes/img-amenidad-salon-usos-multiples-artelia-residencial.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/Assets/Imagenes/img-amenidad-pickleball-artelia-residencial.jpg" alt="amenidad" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="carousel__slider">
                            <img src="/Assets/Imagenes/img-amenidad-dog.park-artelia-residencial.jpg" />
                        </div>
                    </SwiperSlide>
                   </Slider>
    </div>
    </div>
    <div className="contenedor-d-amenidades">
        <div className="contenedor-d-amenidades-principal">
            <h1 className="txt-amenidades wow animate__animated animate__fadeInDown">Amenidades en comunidad</h1>
            <p className="p-amenidades"><strong>Artelia</strong> es un condominio que ofrece una amplia variedad de amenidades para sus residentes. Entre ellas se encuentra una moderna alberca, ideal para disfrutar con familiares y amigos, un práctico salón de usos múltiples diseñado para albergar eventos sociales, así como áreas verdes para disfrutar de paseos al aire libre. Además, <strong>Artelia</strong> cuenta con una cancha de pickleball para los amantes del deporte y un Dog Park, pensado en los miembros peludos de la familia. Todas estas amenidades hacen de Artelia un lugar ideal para vivir, disfrutar, de una vida cómoda y llena de diversión.</p>
          <div className="iconos-amenidades">
               <div className="iconos-amenidades-principal">
                <div className="icono">
                    <img src="/Assets/Icons/icon-arberca-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Alberca</p>
                </div>
                <div className="icono">
                    <img src="/Assets/Icons/icon-juegos-infantiles-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Área infantil</p>
                </div>
                <div className="icono">
                    <img src="/Assets/Icons/icon-usos-multiples-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Usos múltiples</p>
               </div>
                <div className="icono">
                    <img src="/Assets/Icons/icon-areas.verdes-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Áreas verdes</p>
                </div>
                <div className="icono">
                    <img src="/Assets/Icons/icon-jpickball-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Pickleball</p>
                </div>
                <div className="icono">
                    <img src="/Assets/Icons/icon-dog-park-artelia.svg"/>
                    <p className="txt-iconos-amenidades">Dog Park</p>
                </div>
                </div>
            </div>   
            <a>
            <button onClick={()=> props.setShowModal(true)} type="button" className="btn-amenidades">Estoy interesado</button>     </a>  
        </div>
    </div>
</div>
</>
);
<>
</>
}
export default Amenidades;