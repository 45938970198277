import React from 'react';
import './Stiky.scss'
const Stiky = (props) => {
    return ( 
        <div className='contenedor-stiky'>
            <div className='stiky'>
               <a onClick={()=> props.setShowModal(true)}><span className='brochure'>Descargar Brochure</span></a>
            </div>
        </div>
     );
}
 
export default Stiky;