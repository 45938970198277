import React from "react";
import './Footer.scss'

const Footer = () => {

    return(
        <>
        <div className="footer-principal">
            <div className="logo-footer">
                <img className="img-footer" src="/Assets/Logos/img-logotipo-artelia.svg" alt="" />
                <p className="p-footer">El vendedor de Artelia Residencial se reserva el derecho de realizar modificaciones en los planos, diseños exteriores, precios, materiales, especificaciones, acabados y características estándar en cualquier momento y sin previo aviso. Las fotografías, las representaciones son ilustrativas y conceptuales.</p>
                <p className="p-renderinc-footer">Diseño web por:</p>
                <a target="_blank" href="https://renderinc.mx"><img className="renderinc" src="/Assets/Logos/img-logo-renderinc.svg" alt=""/></a>
            </div>
        </div>
        </>
    );
}
export default Footer;