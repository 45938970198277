import React from "react";
import './Seccion.scss'
import ReactPlayer from 'react-player/youtube'

const Seccion = (props) => {
return(
    <>
    <div className="seccion">
        <div className="seccion-i">
            <div className="seccion-i-concepto">
         <h2 className="titulo-seccion wow animate__animated animate__fadeInDown">Tu vida en tranquilidad</h2>
         <p className="parrafo-seccion"><strong>Artelia</strong> ofrece una excelente ubicación y amenidades de primer nivel para aquellos que buscan un estilo de vida sin igual. Disfrute de tranquilidad y seguridad en un entorno de belleza y diseño vanguardista. Únase a nuestra comunidad de residentes que valoran la comodidad y la elegancia en un ambiente sofisticado.
        </p>   
        <a onClick={()=> props.setShowModal(true)}>
        <button type="button" className="btn-brochure">Descargar Brochure</button></a>
        <img className="img-isotipo" src="/Assets/Logos/img-isotipo-artelia.svg"/>
        </div>
        </div>
        <div className="seccion-d">
        <div className="seccion-d-video">
            <img onClick={()=> props.setShowVideo(true)} className="play" src="/Assets/Icons/icon-play-artelia.svg"/>
            <div className="video">
                {/* <video  loop muted autoPlay src="/Assets/Videos/video.mp4"></video> */}
                <div className="fondo_video"></div>
                <ReactPlayer
                    className='react-player'
                    url={'https://www.youtube.com/watch?v=_Ww_Ir45qkY'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width={1000}
                    height={800}
                    style={{objectFit: 'cover'}}
                />
                <p className="txt-seccion">Vida en familia</p>
            </div>
        </div>
        </div>
    </div>
    </>
);

}
export default Seccion;