import React from 'react';
import './Fichas.scss'



const Fichas = (props) => {

    const handleCloseModal = () => {
        props.setFicha(false)
        props.handleClearFicha()
    }

    return ( 
        <div className='modalFichas' >
            <p onClick={handleCloseModal} className='close'>X</p>
            <div onClick={handleCloseModal} className='fondo_black'></div>
            <div className='imgFicha'>
                <img src={props.urlImg} alt="Fichas" />
                <button onClick={()=> props.setShowModal(true)} type="button" className="btn-masterplan">Estoy Interesado</button> 
            </div>
        </div>
     );
}
 
export default Fichas;