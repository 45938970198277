import React, { useState, useCallback, useEffect } from "react";
import "./Menu.scss";
const Menu = (props) => {
  const [y, setY] = useState(window.scrollY);
  const [logo, setLogo] = useState("/Assets/Logos/img_logo_artelia.svg");
  const [scrollMenu, setScroll] = useState("");
  const [ocultar, setOcultar] = useState("ocultar");
  const [mostrar, setMostrar] = useState("mostrar");
  const [open, setOpen] = useState("");
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY < 200) {
          setScroll("");
          setLogo("/Assets/Logos/img_logo_artelia.svg");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY > 200) {
          setScroll("back_menu");
          if (window.innerWidth >= 1024) {
            setLogo("/Assets/Logos/img-isotipo-blanco-artelia.svg");
          }
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const handleMenu = () => {
    setOpen(open === "opened menu-open" ? "" : "opened menu-open");
    setLogo(
      open === "opened menu-open"
        ? "/Assets/Logos/img_logo_artelia.svg"
        : "/Assets/Logos/img_logo_artelia.svg"
    );
  };
  const handlePrototipos = () => {
    handleMenu();
    props.setModalP(true);
  };
  return (
    <>
      <div className={`contenedor_header ${scrollMenu} ${ocultar}`}>
        <div className="menu_telefono">
          <a href="#concepto" className="Cabecera-b">
            Concepto
          </a>
          <a href="#masterplan" className="Cabecera-b">
            Masterplan
          </a>
        </div>
        <div className="menu_logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="menu_telefono">
          <a href="#amenidades" className="Cabecera-b">
            Amenidades
          </a>
          <a href="#ubicacion" className="Cabecera-b">
            Ubicación
          </a>
        </div>
      </div>
      <header className={`${scrollMenu} ${open} ${mostrar}`}>
        <div className="icon_menu">
          <p className="btn-toggle" onClick={() => handleMenu()}>
            <span></span>
          </p>
        </div>
        <div className="menu_logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <nav className="menu">
          <ul>
            <li>
              <a href="#concepto" onClick={() => handleMenu()}>
                CONCEPTO
              </a>
            </li>
            <li>
              <a href="#amenidades" onClick={() => handleMenu()}>
                AMENIDADES
              </a>
            </li>
            <li>
              <a href="#masterplan" onClick={() => handleMenu()}>
                MASTER PLAN
              </a>
            </li>
            <li>
              <a href="#masterplan" onClick={() => handleMenu()}>
                UBICACION
              </a>
            </li>
          </ul>
          <a
            className="descarga"
            onClick={() => {
              props.setShowModal(true);
            }}
          >
            DESCARGAR BROCHURE
          </a>
          <div className="iconos_social">
            <a
              target={"_blank"}
              href="https://www.facebook.com/Artelia-116108294784368/"
            >
              <img src="/Assets/Icons/icon-facebook-artelia.svg" alt="" />
            </a>
            <a
              target={"_blank"}
              href="https://www.instagram.com/artelia_residencial/?igshid=ZjE2NGZiNDQ%3D"
            >
              <img src="/Assets/Icons/icon-instagram-artelia.svg" alt="" />
            </a>
            <a href="https://wa.me/524423312416" target={"_blank"}>
              <img src="/Assets/Icons/icon-whatsapp-artelia.svg" alt="" />
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Menu;
