import React from "react";
import './Concepto.scss'
const Concepto = (props) => {
    return (
      <>
      <div className="contenedor-home"  id="concepto">
        <div className="contenedor-i-principal">
            <div className="contenedor-i-home">
                <img className="img-lote" src="/Assets/Imagenes/img-lotes-residenciales-artelia-residencial.jpg"></img>
                <p className="txt-lotes">102 lotes desde 144 m2</p>
            </div>
        </div>
            <div className="contenedor-d-home">
                <div className="cont-concepto" >                
                <h1 className="txt1-home wow animate__animated animate__fadeInDown">Bienvenido a tu nuevo hogar</h1>
                <h3>Lotes desde 1.1 mdp</h3>
                <div>
                <p className="concepto-home"><strong>Artelia</strong> redefine la vida residencial en un ambiente exclusivo y lleno de comodidades. </p>
                <p className="concepto-home">Este excepcional desarrollo de terrenos habitacionales consta de tan <strong>solo 102 lotes desde 144 m2</strong>, lo que lo convierte en un fraccionamiento verdaderamente exclusivo, diseñado para que cada familia pueda construir la casa de sus sueños en un entorno seguro y con una inigualable calidad de vida.</p>
                </div>
                <a href="#masterplan">
                <button type="button" className="btn-masterplan">Ver master plan</button></a>
                </div>
            </div>
      </div>
      </>
    );
}
export default Concepto;