import React from 'react'
import './Video.scss'
import ReactPlayer from 'react-player/youtube'
const Video = (props) => {
    return (  
        <div className='modalVideo'>
            <p className='close' onClick={()=> props.setShowVideo(false)}>X</p>
            <div onClick={()=>props.setShowVideo(false)} className='fondo_black'></div>
            <div className='video2'>
            {/* <video loop muted autoPlay src="/Assets/Videos/video.mp4"></video> */}
                <ReactPlayer
                    className='react-player'
                    url={'https://www.youtube.com/watch?v=_Ww_Ir45qkY'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={false}
                    width={'100%'}
                    height={'100%'}
                    style={{objectFit: 'cover'}}
                />
            </div>
        </div>
    );
}
 
export default Video;