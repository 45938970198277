import React from "react";
import './Mapa.scss'
import MapaLeaft from "../MapaLeaft/Mapa";


const Mapa = () => {
return(
<>
<div className="contenedor-mapa" id="ubicacion">
<div className="contenedor-i-mapa">
<div className="titulo">
    <h1 className="titulo wow animate__animated animate__fadeInDown">UBICACIÓN</h1>
</div>
<div className="direccion">
    <p>Paseo de Amsterdam, 76920</p>
    <p>Corregidora, Qro.</p>
</div>
    <div className="iconos-mapas">
            <a target={"_blank"} href="https://goo.gl/maps/oaypJPRbgiyrXSwo9"><img src="/Assets/Icons/svg-icon-maps.svg" alt="" /></a>
            <a target={"_blank"} href="https://ul.waze.com/ul?ll=20.53408266%2C-100.42580009&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"><img src="/assets/Icons/svg-icon-waze.svg" alt="" /></a>    
    </div>
    <a target={"_blank"} href='tel:4424540729' className='tel'>
                        T. 442 454 0729
    </a>
</div>
<div className="contenedor-d-mapa">
 <MapaLeaft/> 
</div>
</div>
</>
);
}
export default Mapa;