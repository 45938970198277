import React, { useState } from "react";
import { useFormik } from "formik";
import styled from 'styled-components'
import * as Yup from 'yup';
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

const Formulario=(props) => {
    const [recaptcha, setRecaptcha] = useState("")
    const [errorRecaptcha, showError] = useState("ocultar-error")
    const [botonEnviar, setBotonEnviar] = useState("Enviar")

const onChange = (val) => {
        setRecaptcha(val)
    }
    // console.log('lote ficha', props.LoteFicha)
const {
        errors,isSubmitting
    } = props;
const formik = useFormik({
    initialValues: {
        nombre: '',
        tel: '',
        email: ''
    },
    validationSchema: Yup.object({
        nombre: Yup.string().required('Campo requerido'),
        tel: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "El numero de teléfono no es válido") .min(10,'Deben ser mínimo 10 dígitos').max(10,'Deben ser máximo 10 dígitos').required('Campo requerido'),
        email: Yup.string().email('Correo inválido').required('Campo requerido'),
    }),
    onSubmit: async (values, actions) => {
       // console.log(values)
        // console.log(actions);
        actions.resetForm()
        const payload = {
            ...values,
            lote: props.LoteFicha,
            recaptcha: recaptcha
        }
        if(recaptcha !== ''){
            setBotonEnviar("Enviando...")
            // console.log(props.LoteFicha)
            // if(props.LoteFicha != ''){
                const res = await axios.post(props.LoteFicha != '' ? 'https://artelia.mx/php/indexLocal.php' : 'https://artelia.mx/php/index.php', payload)
            // }else{
            //     res = await axios.post('https://artelia.mx/php/index.php', payload)
            // }
            // console.log(res,"Respuesta")
            if(res.data.success){
                setBotonEnviar("Enviar")
                swal("Enviado", "El correo se ha enviado correctamente", "success");
                if(props.LoteFicha == ''){
                    var link = document.createElement('a');
                    link.href = '/Assets/Brochure_artelia_Movil_13.05.23.pdf'
                    link.download = 'Brochure.pdf';
                    link.dispatchEvent(new MouseEvent('click'));
                }
                formik.resetForm();
            }else{
                if(res.data.error){
                    setBotonEnviar("Enviar")
                    swal("Error", "Ocurrió un problema al enviar el correo", "error");
                    formik.resetForm();
                }
            }
        }else{
            showError('error')
        }
    },
});

const handleCloseModal = () => {
    props.setShowModal(false)
    props.handleClearFicha()
}
return (
    <>
        <Form>
        <div onClick={handleCloseModal} className='fondo_black'></div>
            <div className="contenedor_form">
               <p className='closed' onClick={handleCloseModal}>X</p>
               <img src="/Assets/Logos/img-isotipo-artelia.svg" alt=""/>
               <h1>Tu vida en tranquilidad</h1>
               {
                props.LoteFicha != '' &&
                <p>Ingresa tus datos y nos comunicaremos contigo</p>
               }
                {
                props.LoteFicha == '' &&
                <p>Ingresa tus datos y descarga nuestro brochure</p>
               }
                    <form onSubmit={formik.handleSubmit}>
                            <div className='row'>
                                <div className='column'>
                                    <input
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        placeholder='Nombre'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.nombre}
                                    />
                                    {formik.touched.nombre && formik.errors.nombre ? (
                                        <div>{formik.errors.nombre}</div>
                                    ) : null}
                                </div>
                                <div className='column'>
                                    <input
                                        id="tel"
                                        name="tel"
                                        type="number"
                                        placeholder='Teléfono'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tel}
                                    />
                                    {formik.touched.tel && formik.errors.tel ? (
                                        <div>{formik.errors.tel}</div>
                                    ) : null}
                                </div>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder='Correo electrónico'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div>{formik.errors.email}</div>
                            ) : null}
                            <span>*Al descargar brochure acepta nuestros términos de privacidad.</span>
                            <div className='row'>
                                <div className='recaptcha'>
                                    <ReCAPTCHA
                                        sitekey="6LeRiBEmAAAAAEAjTdG6QUVcrmSNLwucHlg6TUZw"
                                        //sitekey="6LcYyjQaAAAAAGUiRnoKng7Z77D2I_MzefJ6TPM3"
                                        onChange={onChange}
                                    />
                                </div>
                                <button disabled={isSubmitting} type="submit">{botonEnviar}</button>
                            </div>
                    </form>
            </div>
        </Form>
    </>
);

}
const Form = styled.div `
width: 100%;
height: 100vh;
position: fixed;
z-index: 9999;
/* background-color: rgba(0, 0, 0, 0.471); */
overflow-y: auto;
box-sizing: border-box;
.contenedor_form{
    background-color: #899C72;
    width: 40%;
    height: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    box-sizing: border-box;
    @media (min-width:1600px) {
        width: 35%;
        padding: 40px 60px;
    }
    @media (max-width:1024px) {
        width: 60%;
    }
    @media (max-width:768px) {
        width: 80%;
    }
    @media (max-width:550px) {
        width: 100%;
        min-height: 750px;
        padding: 20px;
    }
    .fondo_black{
        background-color: rgba(0, 0, 0, 0.548);
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }
    img{
        position: absolute;
        top: 90px;
        width: 140px;
        @media (max-width:550px) {
            width: 100px;
        }
        @media (max-width:360px) {
            top: 50px;
        }
    }
    h1{
        font-family: Runalto;
        color: white;
        font-size: 50px;
        font-weight: normal;
        padding-top: 115px;
        width: 60%;
        @media (max-width:550px) {
            width: 100%;
            font-size: 35px;
            padding-top: 150px;
        }
        @media (max-width:360px) {
            padding-top: 120px;
        }
    }
    p{
        color: white;
        font-size: 20px;
        font-family: "Helvetica";
        @media (max-width:550px) {
            font-size: 18px;
        }
    }
    span{
        color: white;
        font-size: 15px;
        font-family: "Helvetica";
        @media (max-width:550px) {
            text-align: center;
            font-size: 12px;
        }
    }
    button{
        border: none;
        background-color:#FFFFFF;
        color: #899C72;
        text-decoration: none;
        padding: 10px 20px;
        cursor: pointer;
        font-family: Runalto;
        &:hover{
            /* background-color: #C2B0A1; */
        }
    }
    .closed{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        font-size: 30px;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 35%;
        box-sizing: border-box;
        @media (max-width:550px) {
            height: 60%;
            min-height: 420px;
            max-height: 420px;
        }
        div{
            color: red;
            font-size: 12px;
        }
        .row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            @media (max-width:550px) {
                flex-direction: column;
                row-gap: 20px;
            }
            .column{
                width: 48%;
                box-sizing: border-box;
                @media (max-width:550px) {
                    width: 100%;
                }
            }
        }
        input{
            box-sizing: border-box;
            width: 100%;
            height: 40px;
            border: none;
            padding-left: 10px;
            color: #8A9E77;
            font-size: 16px;
            font-family: "Helvetica";
            outline: none;
            &::placeholder{
                padding-left: 10px;
                color: #8A9E77;
                font-size: 16px;
            }
        }
        input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type=number] { -moz-appearance:textfield; }
    }
}
`
export default Formulario;

