import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./Slider.scss";

// import required modules

export default function Slider() {
  return (
    <>
      <div className="slider-ej">
        <div className="slider-principal">
          <img
            className="img-logo"
            src="/Assets/Logos/img-isotipo-artelia.svg"
            alt=""
          />
          <h2 className="txt1 wow animate__animated animate__fadeInDown">
            Tu espacio, tu hogar
          </h2>
          <h3>Lotes desde 1.1 mdp</h3>
          <a href="#masterplan">
            <button type="button" className="btn-masterplan">
              Ver master plan
            </button>
          </a>
        </div>
        <Swiper
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="mySwiper"
          spaceBetween={30}
          effect={"fade"}
          navigation={false}
          autoplay={{ delay: 3500 }}
        >
          <SwiperSlide>
            <img
              className="estilos"
              src="/Assets/Imagenes/Slider1.jpeg"
              alt=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="estilos"
              src="/Assets/Imagenes/Slider2.jpeg"
              alt=""
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="datos-contacto">
        <div className="datos-center">
          <p className="corregidora">Corregidora Querétaro</p>
          <div className="redes">
            <a
              target={"_blank"}
              href="https://www.instagram.com/artelia_residencial/?igshid=ZjE2NGZiNDQ%3D"
            >
              <img
                className="img-social"
                src="/Assets/Icons/icon-instagram-artelia.svg"
              ></img>
            </a>
            <a
              target={"_blank"}
              href="https://www.facebook.com/Artelia-116108294784368/"
            >
              <img
                className="img-social"
                src="/Assets/Icons/icon-facebook-artelia.svg"
              ></img>
            </a>
            <a target={"_blank"} href="tel:+524424540729">
              <p className="telefono">442 454 0729</p>
            </a>
          </div>
          <li className="li-whatsapp">
            <a
              className="whatsapp"
              href="https://wa.me/524423312416?text=Me gustaría obtener más información sobre Artelia"
              target={"_blank"}
            >
              <img
                className="img-whatsapp"
                src="/Assets/Icons/icon-whatsapp-artelia.svg"
                alt=""
              />
            </a>
          </li>
        </div>
      </div>
    </>
  );
}
