import React, {useState, useEffect, useRef} from "react";
import './MasterPlan.scss'
import { CSSTransition } from "react-transition-group";
import 'imagemapster';
import $ from "jquery";


const MasterPlan = (props) => {
const [showMapa, setShowMapa] = useState(false)
const [showMasterplan, setMasterplan] = useState(true)
const [hideTexto, setHideTexto] = useState("")
const [mostrarMapa, setMostrarMapa] = useState('oculto')
const [hideLoader, setHideLoader] = useState('')
const mapRef = useRef(null);

const imagenes = [
    {
        'name': 'L1',
        'url': '/Assets/Fichas/ficha_artelia-01.jpg'
    },
    {
        'name': 'L2',
        'url': '/Assets/Fichas/ficha_artelia-02.jpg'
    },
    {
        'name': 'L3',
        'url': '/Assets/Fichas/ficha_artelia-03.jpg'
    },
    {
        'name': 'L4',
        'url': '/Assets/Fichas/ficha_artelia-04.jpg'
    },
    {
        'name': 'L5',
        'url': '/Assets/Fichas/ficha_artelia-05.jpg'
    },
    {
        'name': 'L6',
        'url': '/Assets/Fichas/ficha_artelia-06.jpg'
    },
    {
        'name': 'L7',
        'url': '/Assets/Fichas/ficha_artelia-07.jpg'
    },
    {
        'name': 'L8',
        'url': '/Assets/Fichas/ficha_artelia-08.jpg'
    },
    {
        'name': 'L9',
        'url': '/Assets/Fichas/ficha_artelia-09.jpg'
    },
    {
        'name': 'L10',
        'url': '/Assets/Fichas/ficha_artelia-10.jpg'
    }, 
    {
      'name': 'L11',
      'url': '/Assets/Fichas/ficha_artelia-11.jpg'
    },
    {
      'name': 'L12',
      'url': '/Assets/Fichas/ficha_artelia-12.jpg'
    },
    {
      'name': 'L13',
      'url': '/Assets/Fichas/ficha_artelia-13.jpg'
    },
    {
      'name': 'L14',
      'url': '/Assets/Fichas/ficha_artelia-14.jpg'
    },
    {
      'name': 'L15',
      'url': '/Assets/Fichas/ficha_artelia-15.jpg'
    },
    {
      'name': 'L16',
      'url': '/Assets/Fichas/ficha_artelia-16.jpg'
    },
    {
      'name': 'L17',
      'url': '/Assets/Fichas/ficha_artelia-17.jpg'
    },
    {
      'name': 'L18',
      'url': '/Assets/Fichas/ficha_artelia-18.jpg'
    },
    {
      'name': 'L19',
      'url': '/Assets/Fichas/ficha_artelia-19.jpg'
    },
    {
      'name': 'L20',
      'url': '/Assets/Fichas/ficha_artelia-20.jpg'
    },
    {
      'name': 'L21',
      'url': '/Assets/Fichas/ficha_artelia-21.jpg'
    },
    {
      'name': 'L22',
      'url': '/Assets/Fichas/ficha_artelia-22.jpg'
    },
    {
      'name': 'L23',
      'url': '/Assets/Fichas/ficha_artelia-23.jpg'
    },
    {
      'name': 'L24',
      'url': '/Assets/Fichas/ficha_artelia-24.jpg'
    },
    {
      'name': 'L25',
      'url': '/Assets/Fichas/ficha_artelia-25.jpg'
    },
    {
      'name': 'L26',
      'url': '/Assets/Fichas/ficha_artelia-26.jpg'
    },
    {
      'name': 'L27',
      'url': '/Assets/Fichas/ficha_artelia-27.jpg'
    },
    {
      'name': 'L28',
      'url': '/Assets/Fichas/ficha_artelia-28.jpg'
    },
    {
      'name': 'L29',
      'url': '/Assets/Fichas/ficha_artelia-29.jpg'
    },
    {
      'name': 'L30',
      'url': '/Assets/Fichas/ficha_artelia-30.jpg'
    },
    {
      'name': 'L31',
      'url': '/Assets/Fichas/ficha_artelia-31.jpg'
    },
    {
      'name': 'L32',
      'url': '/Assets/Fichas/ficha_artelia-32.jpg'
    },
    {
      'name': 'L33',
      'url': '/Assets/Fichas/ficha_artelia-33.jpg'
    },
    {
      'name': 'L34',
      'url': '/Assets/Fichas/ficha_artelia-34.jpg'
    },
    {
      'name': 'L35',
      'url': '/Assets/Fichas/ficha_artelia-35.jpg'
    },
    {
      'name': 'L36',
      'url': '/Assets/Fichas/ficha_artelia-36.jpg'
    },
    {
      'name': 'L37',
      'url': '/Assets/Fichas/ficha_artelia-37.jpg'
    },
    {
      'name': 'L38',
      'url': '/Assets/Fichas/ficha_artelia-38.jpg'
    },
    {
      'name': 'L39',
      'url': '/Assets/Fichas/ficha_artelia-39.jpg'
    },
    {
      'name': 'L40',
      'url': '/Assets/Fichas/ficha_artelia-40.jpg'
    },
    {
      'name': 'L41',
      'url': '/Assets/Fichas/ficha_artelia-41.jpg'
    },
    {
      'name': 'L42',
      'url': '/Assets/Fichas/ficha_artelia-42.jpg'
    },
    {
      'name': 'L43',
      'url': '/Assets/Fichas/ficha_artelia-43.jpg'
    },
    {
      'name': 'L44',
      'url': '/Assets/Fichas/ficha_artelia-44.jpg'
    },
    {
      'name': 'L45',
      'url': '/Assets/Fichas/ficha_artelia-45.jpg'
    },
    {
      'name': 'L46',
      'url': '/Assets/Fichas/ficha_artelia-46.jpg'
    },
    {
      'name': 'L47',
      'url': '/Assets/Fichas/ficha_artelia-47.jpg'
    },
    {
      'name': 'L48',
      'url': '/Assets/Fichas/ficha_artelia-48.jpg'
    },
    {
      'name': 'L49',
      'url': '/Assets/Fichas/ficha_artelia-49.jpg'
    },
    {
      'name': 'L50',
      'url': '/Assets/Fichas/ficha_artelia-50.jpg'
    },
    {
      'name': 'L51',
      'url': '/Assets/Fichas/ficha_artelia-51.jpg'
    },
    {
      'name': 'L52',
      'url': '/Assets/Fichas/ficha_artelia-52.jpg'
    },
    {
      'name': 'L53',
      'url': '/Assets/Fichas/ficha_artelia-53.jpg'
    },
    {
      'name': 'L54',
      'url': '/Assets/Fichas/ficha_artelia-54.jpg'
    },
    {
      'name': 'L55',
      'url': '/Assets/Fichas/ficha_artelia-55.jpg'
    },
    {
      'name': 'L56',
      'url': '/Assets/Fichas/ficha_artelia-56.jpg'
    },
    {
      'name': 'L57',
      'url': '/Assets/Fichas/ficha_artelia-57.jpg'
    },
    {
      'name': 'L58',
      'url': '/Assets/Fichas/ficha_artelia-58.jpg'
    },
    {
      'name': 'L59',
      'url': '/Assets/Fichas/ficha_artelia-59.jpg'
    },
    {
      'name': 'L60',
      'url': '/Assets/Fichas/ficha_artelia-60.jpg'
    },
    {
      'name': 'L61',
      'url': '/Assets/Fichas/ficha_artelia-61.jpg'
    },
    {
      'name': 'L62',
      'url': '/Assets/Fichas/ficha_artelia-62.jpg'
    },
    {
      'name': 'L63',
      'url': '/Assets/Fichas/ficha_artelia-63.jpg'
    },
    {
      'name': 'L64',
      'url': '/Assets/Fichas/ficha_artelia-64.jpg'
    },
    {
      'name': 'L65',
      'url': '/Assets/Fichas/ficha_artelia-65.jpg'
    },
    {
      'name': 'L66',
      'url': '/Assets/Fichas/ficha_artelia-66.jpg'
    },
    {
      'name': 'L67',
      'url': '/Assets/Fichas/ficha_artelia-67.jpg'
    },
    {
      'name': 'L68',
      'url': '/Assets/Fichas/ficha_artelia-68.jpg'
    },
    {
      'name': 'L69',
      'url': '/Assets/Fichas/ficha_artelia-69.jpg'
    },
    {
      'name': 'L70',
      'url': '/Assets/Fichas/ficha_artelia-70.jpg'
    },
    {
      'name': 'L71',
      'url': '/Assets/Fichas/ficha_artelia-71.jpg'
    },
    {
      'name': 'L72',
      'url': '/Assets/Fichas/ficha_artelia-72.jpg'
    },
    {
      'name': 'L73',
      'url': '/Assets/Fichas/ficha_artelia-73.jpg'
    },
    {
      'name': 'L74',
      'url': '/Assets/Fichas/ficha_artelia-74.jpg'
    },
    {
      'name': 'L75',
      'url': '/Assets/Fichas/ficha_artelia-75.jpg'
    },
    {
      'name': 'L76',
      'url': '/Assets/Fichas/ficha_artelia-76.jpg'
    },
    {
      'name': 'L77',
      'url': '/Assets/Fichas/ficha_artelia-77.jpg'
    },
    {
      'name': 'L78',
      'url': '/Assets/Fichas/ficha_artelia-78.jpg'
    },
    {
      'name': 'L79',
      'url': '/Assets/Fichas/ficha_artelia-79.jpg'
    },
    {
      'name': 'L80',
      'url': '/Assets/Fichas/ficha_artelia-80.jpg'
    },
    {
      'name': 'L81',
      'url': '/Assets/Fichas/ficha_artelia-81.jpg'
    },
    {
      'name': 'L82',
      'url': '/Assets/Fichas/ficha_artelia-82.jpg'
    },
    {
      'name': 'L83',
      'url': '/Assets/Fichas/ficha_artelia-83.jpg'
    },
    {
      'name': 'L84',
      'url': '/Assets/Fichas/ficha_artelia-84.jpg'
    },
    {
      'name': 'L85',
      'url': '/Assets/Fichas/ficha_artelia-85.jpg'
    },
    {
      'name': 'L86',
      'url': '/Assets/Fichas/ficha_artelia-86.jpg'
    },
    {
      'name': 'L87',
      'url': '/Assets/Fichas/ficha_artelia-87.jpg'
    },
    {
      'name': 'L88',
      'url': '/Assets/Fichas/ficha_artelia-88.jpg'
    },
    {
      'name': 'L89',
      'url': '/Assets/Fichas/ficha_artelia-89.jpg'
    },
    {
      'name': 'L90',
      'url': '/Assets/Fichas/ficha_artelia-90.jpg'
    }
    ,
    {
      'name': 'L91',
      'url': '/Assets/Fichas/ficha_artelia-91.jpg'
    }
    ,
    {
      'name': 'L92',
      'url': '/Assets/Fichas/ficha_artelia-92.jpg'
    }
    ,
    {
      'name': 'L93',
      'url': '/Assets/Fichas/ficha_artelia-93.jpg'
    }
    ,
    {
      'name': 'L94',
      'url': '/Assets/Fichas/ficha_artelia-94.jpg'
    }
    ,
    {
      'name': 'L95',
      'url': '/Assets/Fichas/ficha_artelia-95.jpg'
    }
    ,
    {
      'name': 'L96',
      'url': '/Assets/Fichas/ficha_artelia-96.jpg'
    }
    ,
    {
      'name': 'L97',
      'url': '/Assets/Fichas/ficha_artelia-97.jpg'
    }
    ,
    {
      'name': 'L98',
      'url': '/Assets/Fichas/ficha_artelia-98.jpg'
    }
    ,
    {
      'name': 'L99',
      'url': '/Assets/Fichas/ficha_artelia-99.jpg'
    }
    ,
    {
      'name': 'L100',
      'url': '/Assets/Fichas/ficha_artelia-100.jpg'
    }
    ,
    {
      'name': 'L101',
      'url': '/Assets/Fichas/ficha_artelia-101.jpg'
    }
    ,
    {
      'name': 'L102',
      'url': '/Assets/Fichas/ficha_artelia-102.jpg'
    }
    
]
console.log(mapRef.current)
const cambiarImagen = () => {
    setMasterplan(false)
    setShowMapa(true)
    setMostrarMapa('mostrar')
    if (window.innerWidth  > 1024) {
        setHideTexto("hideTexto")
    }
    
}
const regresarImagen = () => {
    setMasterplan(true)
    setShowMapa(false)
    setMostrarMapa('oculto')
    setHideTexto("")
}
const setImagenes = (type) =>  {
    let url = '';
    imagenes.forEach(element => {
      if(element.name == type){
        url = element.url;
      }
    });
    return url
}

window.addEventListener('resize', (event) => {
    if(event.target.innerWidth > 550){
      $(mapRef.current).mapster('resize', event.target.innerWidth, 0, 100);
      setHideLoader('hide')
    }else{
      $(mapRef.current).mapster('resize', 1000, 0, 100);
      setHideLoader('hide')
    }
})

useEffect(() => {
    const initializeImageMapster = () => {
        const options = {
          fillOpacity: 0.8,
          fillColor: "EBE7E1",
          mapKey: 'name',
          isSelectable: false,
          onClick: function(e){
            // console.log('key',e)
            let imagen = setImagenes(e.key)
            // console.log(imagen)
            props.setUrlFicha(imagen)
            props.handleFicha(e.key.substring(1))
          }
        };

        $(mapRef.current).mapster(options);

    };

    const imgElement = mapRef.current;
    if (imgElement.complete) {
      // La imagen ya está cargada, inicializar ImageMapster directamente
      initializeImageMapster();
    } else {
      // La imagen aún no se ha cargado, esperar al evento 'load' para inicializar ImageMapster
        imgElement.addEventListener('load', initializeImageMapster);
    }

    setTimeout(() => {
        window.dispatchEvent(new Event('resize')); 
    }, 300)
   
  return () => {
    $(mapRef.current).mapster('unbind');
  };
},[]);

    return(
        <>
        <div className="contenedor-master-plan" id="masterplan">
        <div className="fondoMasterplan">
          <div className={`loading ${hideLoader}`}>
            <span className="loader"></span>
          </div>
            <img id="master-map" src="/Assets/Simulador/master_plan_artelia.jpg" border="0" alt="" useMap="#mapeadol_Map"  ref={mapRef}/>
            <map name="mapeadol_Map">
                <area name="L1" shape="poly" alt="" coords="383,438, 377,473, 456,485, 461,450, 383,438" href="#1"/>
                <area name="L38" shape="poly" alt="" coords="807,343, 807,343, 885,355, 880,390, 801,378, 807,343" href="#38"/>
                <area name="L15" shape="poly" alt="" coords="558,631, 565,590, 643,601, 639,627, 558,631" href="#15"/>
                <area name="L75" shape="poly" alt="" coords="1499,657, 1491,706, 1587,701, 1590,671, 1499,657" href="#75"/>
                <area name="L2" shape="poly" alt="" coords="388,403, 383,438, 461,450, 466,415, 388,403" href="#2"/>
                <area name="L4" shape="poly" alt="" coords="398,334, 393,368, 472,380, 477,345, 398,334" href="#4"/>
                <area name="L3" shape="poly" alt="" coords="393,368, 388,403, 466,415, 472,380, 393,368" href="#3"/>
                <area name="L5" shape="poly" alt="" coords="404,299, 398,334, 477,345, 482,311, 404,299" href="#5"/>
                <area name="L65" shape="poly" alt="" coords="1442,585, 1465,584, 1470,554, 1391,542, 1386,577, 1442,585" href="#65"/>
                <area name="L56" shape="poly" alt="" coords="1181,581, 1259,592, 1264,558, 1186,546, 1181,581" href="#56"/>
                <area name="L74" shape="poly" alt="" coords="1504,624, 1593,638, 1590,671, 1499,657, 1504,624" href="#74"/>
                <area name="L69" shape="poly" alt="" coords="1529,456, 1549,459, 1607,470, 1604,504, 1524,492, 1529,456" href="#69"/>
                <area name="L6" shape="poly" alt="" coords="607,307, 601,346, 523,334, 530,288, 584,302, 607,307" href="#6"/>
                <area name="L23" shape="poly" alt="" coords="607,307, 656,317, 685,323, 680,358, 601,346, 607,307" href="#23"/>
                <area name="L61" shape="poly" alt="" coords="1407,437, 1402,472, 1324,460, 1329,425, 1373,432, 1407,437, 1407,437" href="#61"/>
                <area name="L60" shape="poly" alt="" coords="1285,418, 1280,453, 1202,441, 1207,405, 1220,407, 1285,418" href="#60"/>
                <area name="L39" shape="poly" alt="" coords="1007,374, 929,362, 923,397, 1002,409, 1007,374" href="39"/>
                <area name="L50" shape="poly" alt="" coords="1002,409, 1007,374, 1050,380, 1085,386, 1080,420, 1002,409" href="#50"/>
                <area name="L24" shape="poly" alt="" coords="728,330, 723,366, 801,378, 807,343, 732,331, 728,330, 728,330" href="#24"/>
                <area name="L31" shape="poly" alt="" coords="770,587, 765,620, 756,621, 686,610, 691,575, 770,587" href="#31"/>
                <area name="L102" shape="poly" alt="" coords="492,678, 496,757, 531,755, 527,677, 492,678" href="#102"/>
                <area name="L101" shape="poly" alt="" coords="527,677, 531,755, 566,753, 563,675, 527,677" href="#101"/>
                <area name="L100" shape="poly" alt="" coords="563,675, 566,753, 601,751, 598,673, 563,675" href="#100"/>
                <area name="L99" shape="poly" alt="" coords="598,673, 601,751, 636,749, 633,671, 598,673" href="#99"/>
                <area name="L98" shape="poly" alt="" coords="633,671, 636,749, 671,748, 668,669, 633,671" href="#98"/>
                <area name="L97" shape="poly" alt="" coords="668,669, 671,748, 707,746, 703,667, 668,669" href="#97"/>
                <area name="L96" shape="poly" alt="" coords="703,667, 707,746, 743,745, 739,666, 703,667" href="96"/>
                <area name="L95" shape="poly" alt="" coords="739,666, 743,745, 778,743, 774,664, 739,666" href="#95"/>
                <area name="L94" shape="poly" alt="" coords="774,664, 778,743, 813,741, 809,662, 774,664" href="#94"/>
                <area name="L93" shape="poly" alt="" coords="809,662, 813,741, 848,739, 844,660, 809,662" href="#93"/>
                <area name="L92" shape="poly" alt="" coords="844,660, 848,739, 883,738, 879,658, 844,660" href="#92"/>
                <area name="L91" shape="poly" alt="" coords="879,658, 883,738, 919,736, 915,657, 879,658" href="#91"/>
                <area name="L90" shape="poly" alt="" coords="915,657, 919,736, 954,734, 950,655, 915,657" href="#90"/>
                <area name="L89" shape="poly" alt="" coords="950,655, 954,734, 989,732, 985,653, 950,655" href="#89"/>
                <area name="L88" shape="poly" alt="" coords="985,653, 989,732, 1024,730, 1020,651, 985,653" href="#88"/>
                <area name="L87" shape="poly" alt="" coords="1020,651, 1024,730, 1059,728, 1055,649, 1020,651" href="#87"/>
                <area name="L86" shape="poly" alt="" coords="1055,649, 1059,728, 1095,727, 1090,647, 1055,649" href="#86"/>
                <area name="L85" shape="poly" alt="" coords="1090,647, 1095,727, 1130,725, 1126,646, 1090,647" href="#85"/>
                <area name="L84" shape="poly" alt="" coords="1126,646, 1130,725, 1165,723, 1161,644, 1126,646" href="#84"/>
                <area name="L83" shape="poly" alt="" coords="1161,644, 1165,723, 1200,721, 1196,642, 1161,644" href="#83"/>
                <area name="L82" shape="poly" alt="" coords="1196,642, 1200,721, 1235,719, 1231,640, 1196,642" href="#82"/>
                <area name="L81" shape="poly" alt="" coords="1231,640, 1235,719, 1270,717, 1266,638, 1231,640" href="#81"/>
                <area name="L80" shape="poly" alt="" coords="1266,638, 1270,717, 1306,716, 1302,636, 1266,638" href="#80"/>
                <area name="L79" shape="poly" alt="" coords="1302,636, 1306,716, 1341,714, 1337,635, 1302,636" href="#79"/>
                <area name="L78" shape="poly" alt="" coords="1337,635, 1341,714, 1376,712, 1372,633, 1337,635" href="#78"/>
                <area name="L77" shape="poly" alt="" coords="1372,633, 1376,712, 1411,710, 1407,631, 1372,633" href="#77"/>
                <area name="L76" shape="poly" alt="" coords="1407,631, 1411,710, 1446,708, 1442,629, 1407,631" href="#76"/>
                <area name="L14" shape="poly" alt="" coords="481,613, 559,624, 565,590, 486,578, 481,613" href="#14"/>
                <area name="L73" shape="poly" alt="" coords="1504,624, 1593,638, 1596,604, 1509,591, 1504,624" href="#73"/>
                <area name="L72" shape="poly" alt="" coords="1509,591, 1596,604, 1598,571, 1514,558, 1509,591" href="#72"/>
                <area name="L70" shape="poly" alt="" coords="1519,525, 1601,537, 1604,504, 1524,492, 1519,525" href="#70"/>
                <area name="L71" shape="poly" alt="" coords="1514,558, 1598,571, 1601,537, 1519,525, 1514,558" href="#71"/>
                <area name="L13" shape="poly" alt="" coords="486,578, 565,590, 570,555, 492,543, 486,578" href="#13"/>
                <area name="L16" shape="poly" alt="" coords="565,590, 643,601, 648,567, 570,555, 565,590" href="#16"/>
                <area name="L12" shape="poly" alt="" coords="492,543, 570,555, 575,520, 497,508, 492,543" href="#12"/>
                <area name="L17" shape="poly" alt="" coords="570,555, 648,567, 654,532, 575,520, 570,555" href="#17"/>
                <area name="L11" shape="poly" alt="" coords="497,508, 575,520, 580,485, 502,473, 497,508" href="#11"/>
                <area name="L18" shape="poly" alt="" coords="575,520, 654,532, 659,497, 580,485, 575,520" href="#18"/>
                <area name="L30" shape="poly" alt="" coords="691,575, 770,587, 775,552, 697,540, 691,575" href="#30"/>
                <area name="L32" shape="poly" alt="" coords="770,587, 848,599, 854,564, 775,552, 770,587" href="#32"/>
                <area name="L29" shape="poly" alt="" coords="697,540, 775,552, 780,517, 702,506, 697,540" href="#29"/>
                <area name="L33" shape="poly" alt="" coords="775,552, 854,564, 859,529, 780,517, 775,552" href="#33"/>
                <area name="L44" shape="poly" alt="" coords="897,571, 975,583, 981,548, 902,536, 897,571" href="#44"/>
                <area name="L45" shape="poly" alt="" coords="975,583, 1054,595, 1059,560, 981,548, 975,583" href="#45"/>
                <area name="L10" shape="poly" alt="" coords="502,473, 580,485, 586,450, 507,438, 502,473" href="#10"/>
                <area name="L19" shape="poly" alt="" coords="580,485, 659,497, 664,462, 586,450, 580,485" href="#19"/>
                <area name="L28" shape="poly" alt="" coords="702,506, 780,517, 786,483, 707,471, 702,506" href="#28"/>
                <area name="L34" shape="poly" alt="" coords="780,517, 859,529, 864,494, 786,483, 780,517" href="#34"/>
                <area name="L43" shape="poly" alt="" coords="902,536, 981,548, 986,513, 908,501, 902,536" href="#43"/>
                <area name="L46" shape="poly" alt="" coords="981,548, 1059,560, 1064,525, 986,513, 981,548" href="#46"/>
                <area name="L55" shape="poly" alt="" coords="1102,569, 1181,581, 1186,546, 1107,534, 1102,569" href="#55"/>
                <area name="L9" shape="poly" alt="" coords="507,439, 586,450, 591,415, 512,404, 507,439" href="#9"/>
                <area name="L20" shape="poly" alt="" coords="586,450, 664,462, 669,427, 591,415, 586,450" href="#20"/>
                <area name="L27" shape="poly" alt="" coords="707,471, 786,483, 791,448, 713,436, 707,471" href="#27"/>
                <area name="L35" shape="poly" alt="" coords="786,483, 864,494, 869,460, 791,448, 786,483" href="#35"/>
                <area name="L42" shape="poly" alt="" coords="908,501, 986,513, 991,478, 913,466, 908,501" href="#42"/>
                <area name="L47" shape="poly" alt="" coords="986,513, 1064,525, 1070,490, 991,478, 986,513" href="#47"/>
                <area name="L54" shape="poly" alt="" coords="1107,534, 1186,546, 1191,511, 1113,499, 1107,534" href="#54"/>
                <area name="L57" shape="poly" alt="" coords="1186,546, 1264,558, 1270,523, 1191,511, 1186,546" href="#57"/>
                <area name="L7" shape="poly" alt="" coords="518,369, 596,381, 601,346, 523,334, 518,369" href="#7"/>
                <area name="L8" shape="poly" alt="" coords="513,404, 591,415, 596,381, 518,369, 513,404" href="#8"/>
                <area name="L22" shape="poly" alt="" coords="596,381, 675,392, 680,358, 601,346, 596,381" href="#22"/>
                <area name="L21" shape="poly" alt="" coords="591,415, 669,427, 675,392, 596,381, 591,415" href="#21"/>
                <area name="L25" shape="poly" alt="" coords="718,401, 796,413, 801,378, 723,367, 718,401" href="#25"/>
                <area name="L26" shape="poly" alt="" coords="713,436, 791,448, 796,413, 718,401, 713,436" href="#26"/>
                <area name="L37" shape="poly" alt="" coords="796,413, 875,425, 880,390, 801,378, 796,413" href="#37"/>
                <area name="L36" shape="poly" alt="" coords="791,448, 869,460, 875,425, 796,413, 791,448" href="#36"/>
                <area name="L40" shape="poly" alt="" coords="918,432, 996,443, 1002,409, 923,397, 918,432" href="#40"/>
                <area name="L41" shape="poly" alt="" coords="913,466, 991,478, 996,443, 918,432, 913,466" href="#41"/>
                <area name="L49" shape="poly" alt="" coords="996,443, 1075,455, 1080,420, 1002,409, 996,443" href="#49"/>
                <area name="L48" shape="poly" alt="" coords="991,478, 1070,490, 1075,455, 996,443, 991,478" href="#48"/>
                <area name="L51" shape="poly" alt="" coords="1123,429, 1202,441, 1207,405, 1129,392, 1123,429" href="#51"/>
                <area name="L52" shape="poly" alt="" coords="1118,464, 1196,476, 1202,441, 1123,429, 1118,464" href="#52"/>
                <area name="L53" shape="poly" alt="" coords="1113,499, 1191,511, 1196,476, 1118,464, 1113,499" href="#53"/>
                <area name="L59" shape="poly" alt="" coords="1196,476, 1275,488, 1280,453, 1202,441, 1196,476" href="#59"/>
                <area name="L58" shape="poly" alt="" coords="1191,511, 1270,523, 1275,488, 1196,476, 1191,511" href="#58"/>
                <area name="L64" shape="poly" alt="" coords="1308,565, 1386,577, 1391,542, 1313,530, 1308,565" href="#64"/>
                <area name="L62" shape="poly" alt="" coords="1318,495, 1397,507, 1402,472, 1324,460, 1318,495" href="#62"/>
                <area name="L63" shape="poly" alt="" coords="1313,530, 1391,542, 1397,507, 1318,495, 1313,530" href="#63"/>
                <area name="L68" shape="poly" alt="" coords="1402,472, 1480,484, 1486,449, 1407,437, 1402,472" href="#68"/>
                <area name="L67" shape="poly" alt="" coords="1397,507, 1475,519, 1480,484, 1402,472, 1397,507" href="#67"/>
                <area name="L66" shape="poly" alt="" coords="1391,542, 1470,554, 1475,519, 1397,507, 1391,542" href="#66"/>
            </map>
            <img className={`${mostrarMapa}`} src="/Assets/Imagenes/img-hotspots-artelia-residencial.jpg"></img>
        </div>

            <div className={`${hideTexto} masterplan`}>
                <h1 className="txt-masterplan wow animate__animated animate__fadeInDown">
                    Master Plan
                </h1>
                <p className="parrafo-masterplan">
                <strong>Artelia</strong> tiene una ubicación estratégica cercana a centros comerciales, restaurantes, escuelas, hospitales y otras comodidades para una vida cotidiana placentera. Además, su cercanía con importantes vías de comunicación como el Paseo Constituyentes y el Libramiento Sur-Poniente permite un fácil acceso a las principales zonas de Querétaro y sus alrededores.
                </p>
            </div>
            <div className="btn-img">
            <CSSTransition
            in={showMasterplan}
            timeout={200}
            classNames="fade"
            unmountOnExit
        >
            <button onClick={()=>cambiarImagen()} type="button" className="btn-masterplan-2">Cerca de todo</button> 
        </CSSTransition>
        <CSSTransition
            in={showMapa}
            timeout={200}
            classNames="fade"
            unmountOnExit
        >
            <button onClick={()=>regresarImagen()} type="button" className="btn-masterplan-3">Regresar a master plan</button> 
        </CSSTransition>
            <div className="img-mp">
            <img className="img-masterplan" src="/Assets/Logos/img-isotipo-artelia.svg" alt=""/>
            </div>
            </div>
        </div>
        </>

    );
}
export default MasterPlan;