import React from "react"; //imr
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import './Mapa.css'


const MapaLeaft= () => {
  const position = [20.534107188434927, -100.42580503458419]
    let DefaultIcon = L.icon({
      iconSize: [43,55],
      iconUrl: "/Assets/Icons/marker-maps-artelia.png",
      //shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;
    return (  
        <>
        <MapContainer center={position} zoom={17} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://api.mapbox.com/styles/v1/bren95/clhqpjyl003n201p44x7j278w/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYnJlbjk1IiwiYSI6ImNsaHFpaTIzMjJmbXMzZm1tM3c3NDUwODQifQ.I63ww5KHzmVSwBiyl8zMrQ"
    />
    <Marker position={position}>
      <Popup>
        Artelia
      </Popup>
    </Marker>
  </MapContainer>
        </>
    );
}
 
export default MapaLeaft;
