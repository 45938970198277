import './App.css';
import { useEffect, useState } from "react";
import Menu from './Components/Menu/Menu'
import Stiky from './Components/Stiky/Stiky';
import Formulario from './Components/Modal/Formulario';
import Slider from './Components/Slider/Slider';
import Concepto from './Components/Concepto/Concepto';
import Seccion from './Components/Seccion/Seccion'
import Amenidades from './Components/Amenidades/Amenidades';
import MasterPlan from './Components/MasterPlan/MasterPlan';
import Mapa from './Components/Mapa/Mapa'
import Footer from './Components/Footer/Footer';
import WOW from 'wowjs';
import 'animate.css';
import { CSSTransition } from "react-transition-group";
import Video from './Components/Modal/Video';
import Fichas from './Components/Fichas/Fichas';



function App() {
  const [hide,sethide] = useState('')
  useEffect(()=>{
    setTimeout(() => {
        sethide("hide")
     }, 10000);
 
   }) 
    useEffect(()=>{
     new WOW.WOW({
     live: false
     }).init();
    //  window.scrollTo(0, 0)
 },[]); 
  const [showModal, setShowModal] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [showFicha, setFicha] = useState(false)
  const [urlImgFicha, setUrlFicha] = useState('')
  const [LoteFicha, setLoteFicha] = useState('')

  const handleFicha = (lote) => {
    setFicha(true)
    setLoteFicha(lote)
  }

  const handleClearFicha = () => {
    // setFicha(false)
    setLoteFicha('')
  }

  return (
    <>
    <CSSTransition
            in={showFicha}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <Fichas 
          setShowModal = {setShowModal}
          urlImg = {urlImgFicha}
          showFicha= {showFicha}
          setFicha = {setFicha}
          LoteFicha = {LoteFicha}
          handleClearFicha = {handleClearFicha}
        />
      </CSSTransition>
    <CSSTransition
            in={showModal}
            timeout={200}
            classNames="desliz"
            unmountOnExit
      >
        <Formulario 
          showModal= {showModal}
          setShowModal = {setShowModal}
          handleClearFicha={handleClearFicha}
          LoteFicha = {LoteFicha}
        />
      </CSSTransition>
      <CSSTransition
            in={showVideo}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <Video 
          showVideo= {showVideo}
          setShowVideo = {setShowVideo}
        />
      </CSSTransition>

    <Menu setShowModal = {setShowModal}/>
    <Stiky 
    setShowModal = {setShowModal}/>
    <Slider/>
    <Concepto/>
    <Seccion
    showModal= {showModal}
    setShowModal = {setShowModal}
    setShowVideo = {setShowVideo}
    />
    <Amenidades 
    showModal= {showModal}
    setShowModal = {setShowModal}/>
    <MasterPlan 
      setUrlFicha = {setUrlFicha}
      handleFicha = {handleFicha}
    />
    <Mapa/>
    <Footer/>
    </>
  );
}

export default App;
